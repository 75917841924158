import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import NavBar from "./NavBar";

import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // Typography,
  Button,
  IconButton,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import FolderIcon from "@mui/icons-material/Folder";
// import DashboardIcon from "@mui/icons-material/Dashboard";
import CodeIcon from "@mui/icons-material/Code";
import SettingsIcon from "@mui/icons-material/Settings";
import DownloadIcon from "@mui/icons-material/Download";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CloseIcon from "@mui/icons-material/Close";

import PerfectScrollbar from "react-perfect-scrollbar";

import config from "./../../config";

export default function MainLayout() {
  const customization = useSelector((state) => state.customization);

  const location = useLocation();
  useEffect(() => {
    scrollTop();
  }, [location]);
  const ps = useRef();
  function scrollTop() {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  }

  // const MenuItems = () => {
  //   return (
  //     <List style={{ width: "fit-content" }}>
  //       <ListItemButton
  //         style={{
  //           height: "50px",
  //           borderRadius:
  //             windowSize.innerWidth > config.magicNumber ? "10px" : "5px",
  //           width: open ? "200px" : "58px",
  //         }}
  //         onClick={() => {
  //           if (windowSize.innerWidth <= config.magicNumber) {
  //             setOpen(false);
  //           }
  //           window.location.href = `${basename}/`;
  //         }}
  //       >
  //         <ListItemIcon>
  //           <PersonIcon />
  //         </ListItemIcon>
  //         {(open || !windowSize.innerWidth > config.magicNumber) && (
  //           <ListItemText primary={open && "Profile"} />
  //         )}
  //       </ListItemButton>
  //       <ListItemButton
  //         style={{
  //           height: "50px",
  //           borderRadius:
  //             windowSize.innerWidth > config.magicNumber ? "10px" : "5px",
  //           width: open ? "200px" : "58px",
  //         }}
  //         onClick={() => {
  //           if (windowSize.innerWidth <= config.magicNumber) {
  //             setOpen(false);
  //           }
  //           window.location.href = `${basename}/portfolio`;
  //         }}
  //       >
  //         <ListItemIcon>
  //           <FolderIcon />
  //         </ListItemIcon>
  //         {(open || !windowSize.innerWidth > config.magicNumber) && (
  //           <ListItemText primary="Portfolio" />
  //         )}
  //       </ListItemButton>
  //       <ListItemButton
  //         style={{
  //           height: "50px",
  //           borderRadius:
  //             windowSize.innerWidth > config.magicNumber ? "10px" : "5px",
  //           width: open ? "200px" : "58px",
  //         }}
  //         onClick={() => {
  //           if (windowSize.innerWidth <= config.magicNumber) {
  //             setOpen(false);
  //           }
  //           window.location.href = `${basename}/hackathon`;
  //         }}
  //       >
  //         <ListItemIcon>
  //           <CodeIcon />
  //         </ListItemIcon>
  //         {(open || !windowSize.innerWidth > config.magicNumber) && (
  //           <ListItemText primary="Hackathon" />
  //         )}
  //       </ListItemButton>
  //       <ListItemButton
  //         style={{
  //           height: "50px",
  //           borderRadius:
  //             windowSize.innerWidth > config.magicNumber ? "10px" : "5px",
  //           width: open ? "200px" : "58px",
  //         }}
  //         onClick={() => {
  //           if (windowSize.innerWidth <= config.magicNumber) {
  //             setOpen(false);
  //           }
  //           window.location.href = `${basename}/timeline`;
  //         }}
  //       >
  //         <ListItemIcon>
  //           <HourglassTopIcon size={24} className="icon" />

  //           {/* <HourglassTopIcon size={24} style={{ color: "white" }} /> */}
  //         </ListItemIcon>
  //         {(open || !windowSize.innerWidth > config.magicNumber) && (
  //           <ListItemText primary="Timeline" />
  //         )}
  //       </ListItemButton>

  //       <ListItemButton
  //         style={{
  //           height: "50px",
  //           borderRadius:
  //             windowSize.innerWidth > config.magicNumber ? "10px" : "5px",
  //           width: open ? "200px" : "58px",
  //         }}
  //         onClick={() => {
  //           if (windowSize.innerWidth <= config.magicNumber) {
  //             setOpen(false);
  //           }
  //           window.location.href = `${basename}/downloads`;
  //         }}
  //       >
  //         <ListItemIcon>
  //           <DownloadIcon />
  //         </ListItemIcon>
  //         {(open || !windowSize.innerWidth > config.magicNumber) && (
  //           <ListItemText primary="Download" />
  //         )}
  //       </ListItemButton>
  //       <ListItemButton
  //         style={{
  //           height: "50px",
  //           borderRadius:
  //             windowSize.innerWidth > config.magicNumber ? "10px" : "5px",
  //           width: open ? "200px" : "58px",
  //         }}
  //         onClick={() => {
  //           if (windowSize.innerWidth <= config.magicNumber) {
  //             setOpen(false);
  //           }
  //           window.location.href = `${basename}/setting`;
  //         }}
  //       >
  //         <ListItemIcon>
  //           <SettingsIcon />
  //         </ListItemIcon>
  //         {(open || !windowSize.innerWidth > config.magicNumber) && (
  //           <ListItemText primary="Settings" />
  //         )}
  //       </ListItemButton>
  //     </List>
  //   );
  // };

  return (
    <div
      className="page-background"
      style={{
        background: config.bgMap(customization.mode, customization.bg),
      }}
    >
      <NavBar />
      <div className="page-content">
        <Outlet />
      </div>
    </div>
  );
}
