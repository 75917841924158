import { Button } from "@mui/material";
// import { Typography } from "@mui/material";

// import config from "./../../config";
export default function NavBar() {
  return (
    <div className="page-navbar">
      <div style={{ gap: "16px", display: "flex" }}>
        <img
          src="/innovestx-icon.png"
          alt=""
          style={{
            height: "31px",
          }}
        />
        <div style={{ gap: "4px" }}>
          <Button className="nav-text"
            onClick={() => {
              window.location.href = `/`;
            }}>User</Button>
          <Button className="nav-text" onClick={() => {
            window.location.href = `/agent`;
          }}>Agent</Button>
        </div>
      </div>
      <div>
        <img
          src="/icons/agent-icon.png"
          alt=""
          style={{
            height: "40px",
          }}
        />
      </div>
    </div>
  );
}
